<template>
  <div v-if="!isLoading">
    <b-alert v-if="isSaved" variant="success" show class="mb-3">Package prices were successfully updated</b-alert>
    <table class="table table-hover">
      <thead>
        <th>Currency</th>
        <th>Name</th>
        <th>Price</th>
        <th class="text-right">Enabled</th>
      </thead>
      <tbody>
        <tr v-for="(item,key) in currencies" :key="'c-'+key" :class="item.enabled ? '' : 'bg-light text-muted'">
          <td>{{item.currency}}</td>
          <td>{{item.currencyName}}</td>
          <td>
            <b-form-input v-model="item.price" size="sm" placeholder="enter price" :disabled="!item.enabled"></b-form-input>
          </td>
          <td class="text-right">
            <b-form-checkbox v-model="item.enabled" size="lg" switch/>
          </td>
        </tr>
      </tbody>
    </table>
    <b-button :disabled="isSaving" variant="primary" @click="save()">
      <span v-if="!isSaving">Save</span>
      <b-spinner v-else small></b-spinner>
    </b-button>
  </div>
  <b-spinner v-else label="Loading..." small></b-spinner>
</template>

<script>
export default {
  props: ['pk'],
    components: {

    },
    data(){
      return {
        isLoading: false,
        isSaved: false,
        isSaving: false,
        currencies: []
      }
    },
    methods: {
      parseCurrency(item){
        item.enabled = true;
        item.price = 0.00;
        return item;
      },

      getCurrencies(){
        return this.$api.get("products/country/currencies",{
          params: {status: "active"}
        })
        .then(({data}) => {
          this.currencies = data.map(this.parseCurrency);
        });
      },

      getPackagePrices(){
        return this.$api.get(`products/package/${this.pk.id}/prices`)
        .then(res => {
          let prices = res.data;
          if(prices.length > 0){
            this.currencies = this.currencies.map(item => {
              let p = prices.find(o => o.currency === item.currency);
              if(p){
                item.price = p.price;
                item.enabled = (p.status && p.status === "active" ? true : false)
              }
              return item;
            })
          }
        });
      },

      save(){
        this.isSaving = true;
        let prices = this.currencies.map(item => {
          return {
            price: item.price,
            currency: item.currency,
            status: item.enabled ? "active" : "disabled"
          }
        });

        this.$api.put(`products/package/${this.pk.id}/prices`,{data: {prices}})
        .then(() => {
          this.isSaving = false;
          this.isSaved = true;
          document.querySelector(".page-with-menu").scrollTop = 0;
          setTimeout(() => {
            this.isSaved = false;
          },3000)
        });
      },

      getNeeded(){
        this.isLoading = true;
        this.getCurrencies()
        .then(() => {
          this.getPackagePrices().then(() => {
            this.isLoading = false;
          });
        });
      }
    },
    mounted(){
      this.getNeeded();
    },
};
</script>
